
import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './images/ymyglogo.png'
import publicityPageTab from './images/publicityPageTab.png'
import Home from './Home';
import Reserve from './Reserve'
import config from './config';
function App() {
  var [user, setUser]=useState({isShow:false})
  window.document.onclick=function(){
    setUser({isShow:false})
  }
  function popupClick(e){
   e.nativeEvent.stopImmediatePropagation()
    setUser({isShow:true})
  }
  function userPopupMove(e){
    var timer=null;
    timer=setTimeout(()=>{
      clearTimeout(timer)
      setUser({isShow:true})
    },200)
    
  }
  function userPopupOut(e){
    
    var timer=null;
    timer=setTimeout(()=>{
      clearTimeout(timer)
      setUser({isShow:false})
    },200)
    
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <a href='#'><img className='logo' src={logo} /></a>
        </h1>
        <div className='App-headerRSwitch App-headerRSwitchListPC'  onClick={popupClick} onMouseOver={userPopupMove} onMouseOut={userPopupOut}>
              <div className='App-headerRSwitchNav'>
                 <p>产品介绍</p>
                 <div>
                   <img src={publicityPageTab} />
                 </div>
              </div>
              {
                user.isShow?(<div className='App-headerRSwitchList' >
                <ul >
                  {
                    config.publicityPageList.map((res,index)=>{
                      return <li key={index} >
                        <a href={config.publicityPageLocation+res.link} target="_blank" >
                          <img src={res.icons} />
                          <span>{res.name}</span>
                        </a>
                        
                        
                      </li>
                    })
                  }
                </ul>
                
              </div>):null
              }
              
            </div>
            <div className='App-headerRSwitch App-headerRSwitchListM'  onClick={popupClick} >
              <div className='App-headerRSwitchNav'>
                 <p>产品介绍</p>
                 <div>
                   <img src={publicityPageTab} />
                 </div>
              </div>
              {
                user.isShow?(<div className='App-headerRSwitchList' >
                <ul >
                  {
                    config.publicityPageList.map((res,index)=>{
                      return <li key={index} >
                        
                        <a href={config.publicityPageLocation+res.link}  >
                          <img src={res.icons} />
                          <span>{res.name}</span>
                        </a>
                        
                      </li>
                    })
                  }
                </ul>
                
              </div>):null
              }
              
            </div>
      </header>
      <div className='App-cc'>
      <Home />
      </div>
      
      <footer className="App-footerBg">
      {config.isIcp?(
          <div className="App-footer">
          <p>版权所有：北京一脉阳光医学信息技术有限公司&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target='_block'> 京ICP备16038310号-2</a>&nbsp;&nbsp;&nbsp;&nbsp;京B2-20191884 <b>|</b><a href='https://terms.rimagdata.com/rimagcloudprivacypolicy.html'>隐私政策</a></p>
          <p className="App-footer-icons"><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502038103' target='_block'> 京公网安备11010502038103号</a></p>
        </div>
      ):(<div className="App-footer">
      <p>版权所有：北京一脉阳光医院管理有限公司&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/#/Integrated/index" target='_block'>京ICP备19013404号</a><b>|</b><a href='https://terms.rimagdata.com/rimagcloudprivacypolicy.html'>隐私政策</a></p>
    </div>)} 
      </footer>
    </div>
  );
}

export default App;
