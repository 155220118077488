var location=window.location.host;
var list=[];

const indexpone =require('./images/indexpone.png');
const indexptwo =require( './images/indexptwo.png');
const indexpthree =require( './images/indexpthree.png');
const indexpfour =require( './images/indexpfour.png');
const indexpfive =require( './images/indexpfive.png');
const publicityPageTabIcon1=require('./images/publicityPageTabIcon1.png').default;
const publicityPageTabIcon2=require('./images/publicityPageTabIcon2.png').default;
const publicityPageTabIcon3=require('./images/publicityPageTabIcon3.png').default;
const publicityPageTabIcon4=require('./images/publicityPageTabIcon4.png').default;
const publicityPageTabIcon5=require('./images/publicityPageTabIcon5.png').default;
const publicityPageTabIcon6=require('./images/publicityPageTabIcon6.png').default;
var isIcp=true;
if(location=='www.rimagcloud.com'){
    isIcp=false;
}
if(location=='www.rimagdata.com'){
    isIcp=true;
}
var publicityPageLocation=''
var publicityPageList=[
    {
        name:'精细化运营管理平台',
        link:'op/',
        icons:publicityPageTabIcon1,
    },
    {
       name:'医学影像质量控制平台',
       link:'qc/',
       icons:publicityPageTabIcon2,
   },
   {
       name:'顾客服务管理平台',
       link:'psm/',
       icons:publicityPageTabIcon3,
   },
   {
       name:'远程咨询平台',
       link:'rcp/',
       icons:publicityPageTabIcon4,
   },
   {
       name:'医共体影像诊断平台',
       link:'ris/',
       icons:publicityPageTabIcon5,
   },
   {
        name:'香橙平台',
        link:'orange/',
        icons:publicityPageTabIcon6,
    }
];
if(location=='www.rimagdata.com' || location=='www.rimagcloud.com' ){
   console.log('生产')
    list=[
        {
            name:'精细化运营管理平台',
            name1:'精细化运营管理平台',
            subTitle:'数据驱动运营',
            imgbg:indexptwo,
            herf:'https://www.rimagcloud.com/op/',
            link:'https://sso.rimagdata.com/login?callback_url=https%3A%2F%2Frinsight.rimagdata.com',
            color:'#2FB79C'
        },
        {
            name:'医学影像质量控制平台',
            name1:'医学影像质量控制平台',
            subTitle:'质量是影像服务的生命线',
            imgbg:indexpthree,
            herf:'https://www.rimagcloud.com/qc/',
            link:'https://sso.rimagdata.com/login?callback_url=https%3A%2F%2Fqc.rimagdata.com',
            color:'#9A72E2'
        },
        {
            name:'医生工作平台',
            name1:'医生工作平台',
            subTitle:'赋能专业诊断能力',
            imgbg:indexpfour,
            herf:'https://www.rimagcloud.com/rcp/',
            link:'https://sso.rimagdata.com/login?callback_url=https%3A%2F%2Fwww.rimagdata.com%2Fdoctor',
            color:'#359E52'
        },
        {
            name:'远程咨询平台',
            name1:'远程咨询平台',
            subTitle:'优质的影像服务触手可及',
            imgbg:indexpone,
            herf:'https://www.rimagdata.com/reserve',
            // link:'https://www-demo.rimagdata.com/ymyx/',
            link:'https://sso.rimagdata.com/login?callback_url=https%3A%2F%2Frcp.rimagdata.com',
            color:'#6287C0'
        }
        // {
        //     name:'顾客服务管理平台',
        //     name1:'顾客服务管理平台',
        //     subTitle:'优质的影像服务触手可及',
        //     imgbg:indexpone,
        //     herf:'https://www.rimagdata.com/reserve',
        //     link:'https://www.rimagdata.com/ymyx/',
        //     color:'#6287C0'
        // },
      
     ]
    publicityPageLocation='https://www.rimagdata.com/'
    
   
}
else if(location=='www-demo.rimagdata.com' ||  location=='www-demo.rimagcloud.com'){
    console.log('测试')
    list=[
        {
            name:'精细化运营管理平台',
            name1:'精细化运营管理平台',
            subTitle:'数据驱动运营',
            imgbg:indexptwo,
            herf:'https://www.rimagcloud.com/op/',
            // link:'https://test-sso.rimagdata.com/login?callback_url=https%3A%2F%2Frinsight.rimagdata.com/test',
            link:'https://rinsight.rimagdata.com/demo?callback_url=https%3A%2F%2Frinsight.rimagdata.com/test',
            color:'#6998E1'
        },
        {
            name:'医学影像质量控制平台',
            name1:'医学影像质量控制平台',
            subTitle:'质量是影像服务的生命线',
            imgbg:indexpthree,
            herf:'https://www.rimagcloud.com/qc/',
            link:'https://qc.rimagdata.com/demo?callback_url=https%3A%2F%2Fqc.rimagdata.com/test',
            color:'#2EAE9A'
        },
        {
            name:'医生工作平台',
            name1:'医生工作平台',
            subTitle:'赋能专业诊断能力',
            imgbg:indexpfour,
            herf:'https://www.rimagcloud.com/rcp/',
            link:'https://cd-demo-sso.rimagdata.com?callback_url=https://cd-demo-sso.rimagdata.com/#/workbench',
            color:'#9A72E2'
        },
        {
            name:'远程咨询平台',
            name1:'远程咨询平台',
            subTitle:'优质的影像服务触手可及',
            imgbg:indexpone,
            herf:'https://www.rimagdata.com/reserve',
            link:'https://rcp.rimagdata.com/demo?callback_url=https%3A%2F%2Fwww-demo.rimagdata.com%2Fdoctor',
            color:'#3DB15F'
        },
        /**
         * 2022-05-17
         * 添加影核AI集成平台
         * *只有Demo环境添加
         *  */
        {
            name:'影核AI集成平台',
            name1:'影核AI集成平台',
            subTitle:'AI一体化服务平台',
            imgbg:indexpone,
            herf:'https://demo.imagecore.com.cn/pacs/#/',
            // link:'https://www-demo.rimagdata.com/ymyx/',
            link:'https://demo.imagecore.com.cn/pacs/#/login',
            color:'#5384F5'
        }
		// {
  //           name:'顾客服务管理平台',
  //           name1:'顾客服务管理平台',
  //           subTitle:'优质的影像服务触手可及',
  //           imgbg:indexpone,
  //           herf:'https://www.rimagdata.com/reserve',
  //           link:'https://www-demo.rimagdata.com/ymyx/',
  //           color:'#6287C0'
  //       },
        
     ]
     publicityPageLocation='https://www-demo.rimagdata.com/'
   
}
else{
    console.log('本地')
    list=[
        {
            name:'精细化运营管理平台',
            name1:'精细化运营管理平台',
            subTitle:'数据驱动运营',
            imgbg:indexptwo,
            herf:'https://www.rimagcloud.com/op/',
            // link:'https://test-sso.rimagdata.com/login?callback_url=https%3A%2F%2Frinsight.rimagdata.com/test',
            link:'https://rinsight.rimagdata.com/demo?callback_url=https%3A%2F%2Frinsight.rimagdata.com/test',
            color:'#2FB79C'
        },
        {
            name:'医学影像质量控制平台',
            name1:'医学影像质量控制平台',
            subTitle:'质量是影像服务的生命线',
            imgbg:indexpthree,
            herf:'https://www.rimagcloud.com/qc/',
            link:'https://qc.rimagdata.com/demo?callback_url=https%3A%2F%2Fqc.rimagdata.com/test',
            color:'#9A72E2'
        },
        {
            name:'医生工作平台',
            name1:'医生工作平台',
            subTitle:'赋能专业诊断能力',
            imgbg:indexpfour,
            herf:'https://www.rimagcloud.com/rcp/',
            link:'https://cd-demo-sso.rimagdata.com?callback_url=https%3A%2F%2Fwww-demo.rimagdata.com%2Fdoctor',
            color:'#359E52'
        },
        {
            name:'远程咨询平台',
            name1:'远程咨询平台',
            subTitle:'优质的影像服务触手可及',
            imgbg:indexpone,
            herf:'https://www.rimagdata.com/reserve',
            link:'https://rcp.rimagdata.com/demo?callback_url=https%3A%2F%2Fwww-demo.rimagdata.com%2Fdoctor',
            color:'#6287C0'
        }
        
     ]
     publicityPageLocation='https://www-demo.rimagdata.com/'
    
}
export default{
    list:list,
    isIcp:isIcp,
    publicityPageLocation:publicityPageLocation,
    publicityPageList:publicityPageList
};