import './Home.css';
import indexbgone from './images/indexbgone.png';
import indexbgtwo from './images/indexbgtwo.png';
import indexbgthree from './images/indexbgthree.png';
import indexbgfour from './images/indexbgfour.png';
import config from './config'

 function Home(){
    var listBg=[
        {
            name:'运营服务',
            subTitle:'移动端的患者、医生、运营体系解决导流、随访、健康管理',
            img:indexbgone
        },
        {
            name:'IT服务',
            subTitle:'云PACS、云RIS、医联体',
            img:indexbgtwo
        },
        {
            name:'医疗服务',
            subTitle:'远程会诊、疑难会诊、质控服务、学科建设',
            img:indexbgthree
        },
        {
            name:'数据服务',
            subTitle:'医学影像大数据标准化、大数据分析、挖掘、对运营及管理提供数据支持',
            img:indexbgfour
        }
    ];
    
    
        
    return (
        
        <div className='home'>
            <div className='home-bg'>
                <div className='home-ul'>
                  {
                     listBg.map((res,index)=>{
                         return <div className='home-li'  key={index}>
                               <div className='home-lititle'>
                                   <img src={res.img} />
                                   <span>{res.name}</span>
                               </div>  
                               <p>{res.subTitle}</p>
                         </div>
                     })
                  }
                </div>
            </div>
            <div className='home-list'>
                {
                    config.list.map((res,index)=>{
                        
                        return <div className='home-listOne' key={index}>
                           <div className='home-listOnec'>
                            <div className='home-listname' dangerouslySetInnerHTML={{__html:res.name}}></div>
                            <div className='home-listname1' >{res.name1}</div>
                            <div className='home-listbroder'></div>
                            <div className='home-listsubtitle'>{res.subTitle}</div>
							<div className='home-listbtn'>
							   <a href={res.link} target='_blank'>开始体验</a>
							</div>
							{/* 
								index === config.list.length-1?
								<div className='home-listbtn home-listgreybtn'>
								    <a>暂未开放</a>
								</div>:
								<div className='home-listbtn'>
								   <a href={res.link} target='_blank'>开始体验</a>
								</div> */
							}
                            </div>
                            <div className='home-listbg' style={{backgroundColor:res.color}}>
                                {/* <img src={res.imgbg} /> */}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
}
export default Home;